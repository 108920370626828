import { SvgIcon, SvgIconProps } from "@mui/material";
import { memo } from "react";
const SvgCart32 = (props: SvgIconProps) => {
  const {
    sx,
    ...other
  } = props;
  return <SvgIcon xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 32 32" sx={{
    ...sx
  }} {...other}><defs><path id="cart-32_svg__a" d="M25.295 3.558c1.021 0 1.863.8 1.916 1.82l1.117 21.579-.006.2a1.92 1.92 0 0 1-1.911 1.757H5.591l-.253-.016a1.92 1.92 0 0 1-1.665-1.99L4.648 5.39a1.922 1.922 0 0 1 1.918-1.832zm0 1.512H6.565a.406.406 0 0 0-.406.389l-.974 21.517a.406.406 0 0 0 .389.425h20.837a.408.408 0 0 0 .406-.408L25.703 5.456a.406.406 0 0 0-.408-.384ZM11.108 7.569a.7.7 0 0 1 .7.7c0 3.4 2.18 4.949 4.2 4.949a3.943 3.943 0 0 0 2.854-1.2 5.239 5.239 0 0 0 1.327-3.749.7.7 0 1 1 1.4 0l-.013.001a6.605 6.605 0 0 1-1.721 4.723 5.337 5.337 0 0 1-3.856 1.622c-2.779 0-5.591-2.18-5.591-6.346a.7.7 0 0 1 .7-.7" /></defs><g fill="none" fillRule="evenodd"><mask id="cart-32_svg__b" fill="#fff"><use xlinkHref="#cart-32_svg__a" /></mask><g fill="currentColor" mask="url(#cart-32_svg__b)"><path d="M0 0h32v32H0z" /></g></g></SvgIcon>;
};
const Memo = memo(SvgCart32);
export default Memo;